/**
 * A simple set of CSS rules that resets the styling
 * of all HTML elements to a consistent baseline.
 */
*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 100%; /* 1rem = 16px */
}

body {
  color: #0F172A; 
	font-size: 1rem; /* 16px */
	font-family: Roboto, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}